module.exports = [
  {
    default: true,
    prefix: '',
    locale: 'fr',
    home: '/',
    label: 'Français',
    shortLabel: 'FR',
    prismicLocale: 'fr-fr',
    slugs: {
      offers: 'nos-offres',
      blog: 'actualites',
      contact: 'contactez-nous',
      aboutUs: 'qui-sommes-nous'
    }
  },
  {
    prefix: '/en',
    locale: 'en',
    home: '/en',
    label: 'English',
    shortLabel: 'EN',
    prismicLocale: 'en-gb',
    slugs: {
      offers: 'our-offers',
      blog: 'news',
      contact: 'contact-us',
      aboutUs: 'about-us'
    }
  }
]
