import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useLocation } from '@reach/router'
import languages from './index'
import messagesFr from './fr.json'
import messagesEn from './en.json'

const messages = {
  fr: messagesFr,
  en: messagesEn
}

export const IntlWrapper = ({ children }) => {
  const location = useLocation()
  const [language, setLanguage] = useState('fr')

  useEffect(() => {
    const getLanguageFromURL = location.pathname.split('/')[1]
    const defaultLocale = languages.find((language) => language.default).locale

    if (languages.find((language) => language.locale === getLanguageFromURL)) {
      setLanguage(getLanguageFromURL)
    } else {
      setLanguage(defaultLocale)
    }
  }, [location])

  return (
    <IntlProvider locale={ language } messages={ messages[language] }>
      {children}
    </IntlProvider>
  )
}

export default IntlWrapper
