import React from 'react'
import { RecoilRoot } from 'recoil'
import IntlWrapper from './src/i18n/IntlWrapper'

export const wrapPageElement = ({ element, props }) => (
  <RecoilRoot { ...props }>
    <IntlWrapper>
      {element}
    </IntlWrapper>
  </RecoilRoot>
)
